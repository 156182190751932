import { Component, Vue } from 'vue-property-decorator'
import { Service } from 'typedi'
import { mapState } from 'vuex'

type OperatorFunctions = {
  [key: string]: (a: any, b: any) => boolean;
}

@Service()
@Component({
  name: 'ShowIfMixin',
  computed: {
    ...mapState('event', ['eventAllContent'])
  }
})
export default class ShowIfMixin extends Vue {
  eventAllContent!: Record<string, any>;

  get optionGroups () {
    return this.eventAllContent.option_groups
  }

  operatorFunctions: OperatorFunctions = {
    '=': (a: any, b: any) => {
      if (typeof a === 'object' && typeof b === 'object') {
        // get option group of field.
        const fieldOptionGroupOptions = this.optionGroups.filter((group: any) => {
          return group.uuid === a.optionGroup
        })[0].options
        const showIfGroupOptions = this.optionGroups.filter((group: any) => {
          return group.uuid === b.optionGroup
        })[0].options

        // get option value;
        const fieldOptionValue = fieldOptionGroupOptions.filter((option: any) => {
          return option.uuid === a.value
        })
        const showIfOptionValue = showIfGroupOptions.filter((option: any) => {
          return option.uuid === b.value
        })
        // if no value match, default to zero. Otherwise use the value given.
        const aValue = fieldOptionValue.length === 0 ? 0 : fieldOptionValue[0].name
        const bValue = showIfOptionValue.length === 0 ? 0 : showIfOptionValue[0].name

        return aValue === bValue
      } else {
        return a === b
      }
    },
    '!=': (a: any, b: any) => a !== b,
    '>': (a: any, b: any) => parseInt(a) > parseInt(b),
    '<': (a: any, b: any) => parseInt(a) < parseInt(b),
    '>=': (a: any, b: any) => {
      if (typeof a === 'object' && typeof b === 'object') {
        // get option group of field.
        const fieldOptionGroupOptions = this.optionGroups.filter((group: any) => {
          return group.uuid === a.optionGroup
        })[0].options
        const showIfGroupOptions = this.optionGroups.filter((group: any) => {
          return group.uuid === b.optionGroup
        })[0].options

        // get option value;
        const fieldOptionValue = fieldOptionGroupOptions.filter((option: any) => {
          return option.uuid === a.value
        })
        const showIfOptionValue = showIfGroupOptions.filter((option: any) => {
          return option.uuid === b.value
        })

        // if no value match, default to zero. Otherwise use the value given.
        const aValue = fieldOptionValue.length === 0 ? 0 : fieldOptionValue[0].name
        const bValue = showIfOptionValue.length === 0 ? 0 : showIfOptionValue[0].name

        // compare and return.
        return parseInt(aValue) >= parseInt(bValue)
      } else {
        return parseInt(a) >= parseInt(b)
      }
    },
    '<=': (a: any, b: any) => parseInt(a) <= parseInt(b),
    '^=': (a: any, b: any) => (a || '').includes(b),
    '!^=': (a: any, b: any) => !(a || '').includes(b),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    '=null': (a: any, b = null) => a === null || a === '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    '!=null': (a: any, b = null) => a !== null && a !== ''
  }
}
