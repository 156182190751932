import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { ActionType } from '@/modules/common/enums/action-types.enum'
import { ValidationObserver } from 'vee-validate'
import { mapState } from 'vuex'
import { Component, Ref, Prop, Watch } from 'vue-property-decorator'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrNewCustomFieldForm',
  computed: {
    ...mapState('formbuilder', ['eventFields'])
  }
})
export default class GtrNewCustomFieldForm extends GtrSuper {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

    @Ref()
    readonly observerNewCustomFieldForm!: InstanceType<typeof ValidationObserver>;

    data () {
      return {
        loading: false,
        submitting: false,
        showForm: false,
        label: null,
        new_custom_field: {
          field: null,
          label: '',
          table: 'participants',
          custom_field: true,
          required: false
        },
        requiredField: false,
        fields: {
          headers: [
            {
              text: 'Field',
              align: 'start',
              sortable: true,
              value: 'field'
            }, {
              text: 'Label',
              align: 'start',
              sortable: true,
              value: 'label'
            }, {
              text: 'Required',
              align: 'start',
              sortable: false,
              value: 'required'
            }, {
              text: '',
              align: 'start',
              sortable: false,
              value: 'options'
            }
          ],
          items: []
        }
      }
    }

    @Watch('visible', { immediate: true })
    onVisibleValueChange (newVisibleValue: boolean) {
      this.$data.showForm = newVisibleValue
    }

    @Watch('label', { immediate: true })
    onNewCustomFieldChange (newValue: string) {
      this.$data.new_custom_field.label = newValue
      this.$data.new_custom_field.field = this.generateField(newValue)
    }

    mounted () {
      this.clearFormAndModel()
      this.fetchCustomFields()
      this.$store.dispatch('formbuilder/getEventFields', { event_uuid: this.$route.params.event_uuid })
    }

    get systemGeneratedFieldsUuids () {
      return ((this as any).eventFields || []).filter(field => field.standard_field).map(field => field.field)
    }

    async submit () {
      try {
        this.$data.new_custom_field.required = this.$data.requiredField
        this.$data.submitting = true
        const customField = this.$data.new_custom_field.field.replaceAll('custom_', '')
        if (this.systemGeneratedFieldsUuids.includes(customField.toLowerCase())) {
          Container.get(Notification).error(`${customField} already exists as a system generated field`)
          return
        }
        await this.$store.dispatch('event/addCustomField',
          { event_uuid: this.$route.params.event_uuid, data: this.$data.new_custom_field })
        this.$emit('action', { type: ActionType.SUCCESS, message: 'Custom field successfully created.' })
        Container.get(Notification).success('Custom field successfully created.')
        await this.fetchCustomFields()
        this.clearFormAndModel()
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }

    handleClose () {
      this.clearFormAndModel()
      this.$emit('close')
    }

    async saveCustomFields (field: Record<string, any>): Promise<void> {
      try {
        await this.$store.dispatch('event/updateCustomField', {
          event_uuid: this.$route.params.event_uuid,
          data: field
        })
      } catch (e) {
        Container.get(ErrorHandlerService).error(e)
      }
    }

    async handleRemove (uuid: string) {
      try {
        this.$data.loading = true
        await this.$store.dispatch('event/deleteCustomField',
          { event_uuid: this.$route.params.event_uuid, field_uuid: uuid })

        await this.fetchCustomFields()
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }

    async fetchCustomFields () {
      try {
        this.$data.loading = true
        const custom_fields = await this.$store.dispatch('event/loadCustomFields', { event_uuid: this.$route.params.event_uuid })
        this.$data.fields.items = []
        custom_fields.forEach(item => {
          if (item.field.includes('custom_')) {
            this.$data.fields.items.push(item)
          }
        })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }

    private showServerErrors (errors: Record<string, string>): void {
      this.observerNewCustomFieldForm.setErrors(errors)
    }

    private clearFormAndModel () {
      this.$data.new_custom_field = {
        field: null,
        label: '',
        table: 'participants',
        custom_field: true
      }
      this.$data.label = null
      this.observerNewCustomFieldForm.reset()
    }

    private generateField (label: string): string {
      if (label != null) {
        return 'custom_' + label.replaceAll(' ', '_').toLowerCase()
      }
      return ''
    }
}
