import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { ActionType } from '@/modules/common/enums/action-types.enum'
import { ValidationObserver } from 'vee-validate'
import { Component, Ref, Prop, Watch } from 'vue-property-decorator'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrNewAttendeeForm'
})
export default class GtrNewAttendeeForm extends GtrSuper {
    @Prop({ required: true, type: Boolean, default: false })
    visible: boolean | undefined

    @Prop({ required: true, type: Array, default: '' })
    custom_fields: Array<object> | undefined

    @Ref()
    readonly observerNewAttendeeForm!: InstanceType<typeof ValidationObserver>;

    data () {
      return {
        submitting: false,
        showForm: false,
        newAttendee: {
          first_name: null,
          last_name: null,
          email: null
        }
      }
    }

    @Watch('visible', { immediate: true })
    onVisibleValueChange (newVisibleValue: boolean) {
      this.$data.showForm = newVisibleValue
    }

    async mounted () {
      this.clearFormAndModel()
    }

    async submit () {
      try {
        this.$data.submitting = true
        const attendee = await this.$store.dispatch('attendee/addAttendee', { event_uuid: this.$route.params.event_uuid, data: this.$data.newAttendee })
        this.$emit('action', { type: ActionType.SUCCESS, message: 'Attendee successfully created.' })
        Container.get(Notification).success('Attendees successfully created.')
        this.$emit('close')
        this.clearFormAndModel()
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.submitting = false
      }
    }

    handleClose () {
      this.clearFormAndModel()
      this.$emit('close')
    }

    private showServerErrors (errors: Record<string, string>): void {
      this.observerNewAttendeeForm.setErrors(errors)
    }

    private clearFormAndModel () {
      this.$data.newAttendee = {
        first_name: null,
        last_name: null,
        email: null
      }
      this.observerNewAttendeeForm.reset()
    }
}
