import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Layout } from '@/modules/common/enums/layouts.enum'
import { Component, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrEventLayout',
  computed: {
    ...mapState('company', ['company']),
    ...mapState('event', ['event', 'eventModules', 'eventBookmarks']),
    ...mapState('module', ['eventOverview', 'gtrModules', 'activatedEventModules', 'support']),
    ...mapState('common', ['noticeDisplayed']),
    ...mapState('leads', ['changelog']),
    ...mapGetters('media', ['defaultFonts', 'fontList'])
  }
})
export default class GtrEventLayout extends GtrSuper {
  eventModules!: any;
  eventOverview!: any;
  gtrModules!: any;
  support!: any;
  noticeDisplayed!: boolean;
  event!: Record<string, any>;
  defaultFonts!: Array<Record<string, any>>;
  fontList!: Array<Record<string, any>>;

  get menuItems () {
    return [
      this.eventOverview,
      ...this.gtrModules,
      this.support
    ]
  }

  data () {
    return {
      miniVariant: false,
      isTrackActivated: false,
      isPromoteActivated: false,
      mountComplete: false,
      closeBookmarkMenuOnClick: true,
      editBookmarkModal: false,
      addBookmarkModal: false,
      deleteBookmarkModal: false,
      editBookmarkUuid: null,
      deleteBookmarkUuid: null,
      addBookmarkData: {
        name: null,
        url: null
      },
      editBookmarkData: {
        name: null,
        url: null
      }
    }
  }

  // TODO(zb): Make a type for bookmark
  get activeBookmarks () {
    return ((this as any).eventBookmarks || []).filter((bookmark: any) => bookmark.active === 1)
  }

  private handleAddBookmarkOpen () {
    this.$data.addBookmarkModal = true
    this.$data.closeBookmarkMenuOnClick = false
    this.$data.addBookmarkData.url = window.location.href
  }

  private handleAddBookmarkClose () {
    this.$data.addBookmarkModal = false
    this.$data.closeBookmarkMenuOnClick = true
    this.$data.addBookmarkData.name = null
    this.$data.addBookmarkData.url = null
  }

  private async addBookmark () {
    try {
      await this.$store.dispatch('event/addBookmark', {
        event_uuid: this.$route.params.event_uuid,
        data: {
          ...this.$data.addBookmarkData
        }
      })
      this.handleAddBookmarkClose()
    } catch (err) {
      Container.get(ErrorHandlerService).error(err)
    }
  }

  private handleEditBookmarkOpen (uuid: string) {
    this.$data.editBookmarkUuid = uuid
    const bookmark = this.activeBookmarks.filter((bookmark: any) => bookmark.uuid === uuid).pop()
    this.$data.editBookmarkModal = true
    this.$data.closeBookmarkMenuOnClick = false
    this.$data.editBookmarkData.name = bookmark.name
    this.$data.editBookmarkData.url = bookmark.url
  }

  private handleEditBookmarkClose () {
    this.$data.editBookmarkUuid = null
    this.$data.editBookmarkModal = false
    this.$data.closeBookmarkMenuOnClick = true
    this.$data.editBookmarkData.name = null
    this.$data.editBookmarkData.url = null
  }

  private async editBookmark () {
    try {
      await this.$store.dispatch('event/updateBookmark', {
        event_uuid: this.$route.params.event_uuid,
        bookmark_uuid: this.$data.editBookmarkUuid,
        data: {
          ...this.$data.editBookmarkData
        }
      })
      this.handleEditBookmarkClose()
    } catch (err) {
      Container.get(ErrorHandlerService).error(err)
    }
  }

  private handleDeleteBookmarkOpen (uuid: any) {
    this.$data.deleteBookmarkUuid = uuid
    this.$data.deleteBookmarkModal = true
    this.$data.closeBookmarkMenuOnClick = false
  }

  private handleDeleteBookmarkClose () {
    this.$data.deleteBookmarkUuid = null
    this.$data.deleteBookmarkModal = false
    this.$data.closeBookmarkMenuOnClick = true
  }

  private async deleteBookmark () {
    try {
      await this.$store.dispatch('event/updateBookmark', {
        event_uuid: this.$route.params.event_uuid,
        bookmark_uuid: this.$data.deleteBookmarkUuid,
        data: {
          active: 0
        }
      })
      this.handleDeleteBookmarkClose()
    } catch (err) {
      Container.get(ErrorHandlerService).error(err)
    }
  }

  private fetchBookmarks () {
    try {
      this.$store.dispatch('event/fetchBookmarks', this.$route.params.event_uuid).catch(error => Container.get(ErrorHandlerService).error(error))
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private fetchLeadsChangelog () {
    try {
      const changelogData = { type: 'orders_settings', subtype: 'default', event_uuid: this.$route.params.event_uuid }
      this.$store.dispatch('leads/getChangelog', changelogData).catch(error => Container.get(ErrorHandlerService).error(error))
    } catch (error) {
      if (error.status !== 404) {
        Container.get(ErrorHandlerService).error(error)
      }
    }
  }

  get mostRecentLeadsChangelogUpdatedAtTimestamp () {
    return (this as any)?.changelog?.data?.[0]?.updated_at ?? null
  }

  /**
   * When the layout for events loads it should fetch the event and company info
   * As well as the event modules for the current event.
   */
  async mounted () {
    try {
      if (!this.$store.state.security.currentUser) {
        await this.$store.dispatch('security/getCurrentUser')
      }
      this.fetchLeadsChangelog()
      this.fetchBookmarks()
      await this.loadEventAndCompanyInfo()
      await this.fetchEventModules()
      this.$data.mountComplete = true
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  /**
   * The Event layout is cached because of keep-alive.
   * This hook will refetch the modules everytime the layout is reactivated.
   */
  async activated () {
    try {
      await this.fetchEventModules()
    } catch (err) {
      Container.get(ErrorHandlerService).error(err)
    }
  }

  @Watch('eventModules')
  async onEventModulesChange (payload: any) {
    if (payload.SESSION_TRACKING.enabled) this.$data.isTrackActivated = true
    if (!payload.SESSION_TRACKING.enabled) this.$data.isTrackActivated = false
    if (payload.PROMOTE.enabled) this.$data.isPromoteActivated = true
    if (!payload.PROMOTE.enabled) this.$data.isPromoteActivated = false
    const { REGISTRATION, LEADS } = this.eventModules
    if (REGISTRATION?.enabled || LEADS?.enabled) {
      this.$store.commit('module/SET_SUBMODULE_VISIBILITY', { routeName: 'level-two.event.payments', active: true })
    } else {
      this.$store.commit('module/SET_SUBMODULE_VISIBILITY', { routeName: 'level-two.event.payments', active: false })
    }
  }

  @Watch('$route', { immediate: true })
  async onRouteChange () {
    if (this.$route.params.uuid && this.$route.params.event_uuid) {
      this.$data.menuItems = this.makeSideBarMenu(this.$route.params.uuid, Layout.EVENT, this.$route.params.event_uuid)
      if (this.$store.state.event.eventAllContent) {
        if (this.$store.state.event.eventAllContent.event.uuid !== this.$route.params.event_uuid) {
          await this.loadEventAndCompanyInfo()
        }
      }
    }
  }

  get regUrl () {
    const event = this.$store.state.event.event
    if (event?.event_identifier) {
      return `${process.env.VUE_APP_REG_URL}/${event.event_identifier}`
    }
  }

  get ordersUrl () {
    const event = this.$store.state.event.event
    if (event?.event_identifier) {
      return `${process.env.VUE_APP_REG_URL}/${event.event_identifier}/leads`
    }
  }

  navigateTo (route: any) {
    this.$router.push(route).catch(error => Container.get(ErrorHandlerService).error(error))
  }

  private async loadEventAndCompanyInfo (): Promise<void> {
    try {
      await this.$store.dispatch('common/showLoader', { value: true })
      await this.$store.dispatch('company/fetchCompany', this.$route.params.uuid)
      await this.$store.dispatch('event/fetchEvent', this.$route.params.event_uuid)
      await this.$store.dispatch('event/fetchEventModules', this.$route.params.event_uuid)
      await this.$store.dispatch('event/getEventAllContent', this.$route.params.event_uuid)
      await this.fetchFontList()
      this.$bus.$emit('gtr.event.content_all')
      await this.$store.dispatch('common/hideLoader')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async fetchEventModules (): Promise<void> {
    try {
      const { event_uuid } = this.$route.params
      await this.$store.dispatch('module/fetchEventModules', event_uuid)
    } catch (err) {
      Container.get(ErrorHandlerService).error(err)
    }
  }

  async fetchFontList () {
    const { uuid: companyUuid } = this.$route.params
    await this.$store.dispatch('media/getFontStores', companyUuid)
  }
}
